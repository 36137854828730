import React from 'react';
import { withTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'

// Components
import MainLayout from '../../components/main-layout';
import ReleaseBox from './release-box';
import ReleasesList from './releases-list';
import HeaderSection from './header-section';
import QueryReleases from './queryReleases';
import gatsbyConfig from '../../../gatsby-config';

const Releases = (props) => {
    const { i18n } = useTranslation();
    const { t, pageContext } = props;
    const releaseResponse = QueryReleases();
    const productUpdates = releaseResponse.allStrapiProductUpdate.edges;

    // We sort the product updates by publication date, starting with most recent dates
    const sortedProductUpdates = productUpdates.sort(function(a, b) {
        a = new Date(a.node.DatePublished);
        b = new Date(b.node.DatePublished);
        return a > b ? -1 : a < b ? 1 : 0;
    });
    const strapiUrl = gatsbyConfig.siteMetadata.strapiUrl;

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="header" {...props}/>
            <div key="container" className="container mt40">
                <div className="row">
                    <div className="col-12 col-md-4 mb20">
                        <ReleasesList
                            releases={pageContext && pageContext.releases}
                            pathname={props.location.pathname}
                        />
                    </div>
                    <div className="col-12 col-md-8">
                        {(
                            sortedProductUpdates?.map((release, index) => {
                                let returnedValue = null;

                                 // One item for each language
                                 switch(i18n.language) {

                                    // This is the main manguage (on strapi)
                                    case "en":
                                        returnedValue = [
                                            <ReleaseBox
                                                key={release.node.id}
                                                title={release.node.Title}
                                                date={release.node.DatePublished}
                                                html={release.node.Content?.data?.Content}
                                                style={{ marginBottom: 15 }}
                                                slug={release.node.slug}
                                                detailPage={false}
                                                baseUrlImage={strapiUrl}
                                            />,
                                            release.length - 1 !== index ? <hr key={`${index}-separator`} className="mt40 hr-normal" /> : null || t('productUpdates.noContentLabel')
                                        ];
                                        break;
                                    
                                    case "es":
                                        if(release.node.localizations?.data[0]) {
                                            returnedValue = [
                                                <ReleaseBox
                                                    key={release.node.id}
                                                    title={release.node.localizations?.data[0]?.attributes?.Title}
                                                    date={release.node.localizations?.data[0]?.attributes?.DatePublished}
                                                    html={release.node.localizations?.data[0]?.attributes?.Content}
                                                    style={{ marginBottom: 15 }}
                                                    slug={release.node.localizations?.data[0]?.attributes?.slug}
                                                    detailPage={false}
                                                    baseUrlImage={strapiUrl}
                                                />,
                                                release.length - 1 !== index ? <hr key={`${index}-separator`} className="mt40 hr-normal" /> : null || t('productUpdates.noContentLabel')
                                            ]; 
                                        }
                                        break;

                                    default:
                                        returnedValue= null;
                                } 

                                return returnedValue;
                            })
                        )}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default withTranslation()(Releases);