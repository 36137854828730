import React from 'react';
import { withTranslation } from 'react-i18next'
import { Link } from "gatsby";
import moment from 'moment';
import QueryReleases from './queryReleases';
import { useTranslation } from 'react-i18next';

const ReleasesList = ()=>  {

    const { i18n } = useTranslation();
    const releaseResponse = QueryReleases();
    const release = releaseResponse.allStrapiProductUpdate.edges;
    const releaseSort = release.sort(function(a, b) { a = new Date(a.node.DatePublished); b = new Date(b.node.DatePublished); return a>b ? -1 : a<b ? 1 : 0; });

    const styles = {
        container: {
            padding: 25,
            background: '#eceff1'
        },
        title: {
            color: 'black',
            marginBottom: '3px'
        }
    };
        
    return (
        <div className="container" style={styles.container}>
            {releaseSort.map((release) => {
                return (
                    <div key={release.node.id}>
                        <h6 style={styles.title} >
                            <Link
                                to={i18n.language === "en" ? "/en/product-updates/" + release.node.slug + "/" : "/es/actualizaciones-producto/" + release.node.localizations?.data[0]?.attributes?.slug + "/"}
                                style={{ color: 'black' }}
                            >
                                {i18n.language === "en" ? release.node.Title : release.node.localizations?.data[0]?.attributes?.Title}
                            </Link>
                        </h6>
                        {i18n.language === "en" ? 
                            <>
                                <div  style={{ fontSize: 13 }}>{moment(release.node.DatePublished).format('LL')}</div> 
                                <hr className="hr-normal mv10" />
                            </>
                        : 
                            i18n.language === "es" && release.node.localizations?.data[0] ? 
                                <>  
                                    <div  style={{ fontSize: 13 }}>{moment(release.node.DatePublished).format('LL')}</div>
                                    <hr className="hr-normal mv10" />
                                </>
                            : null
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default withTranslation()(ReleasesList);